<template>
  <v-row>
    <v-col cols="12">
      <v-switch
        :label="$t('active')"
        v-model="obCurrencyRate.active"
      ></v-switch>
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obCurrencyRate.name" />
    </v-col>

    <v-col cols="12">
      <v-textarea
        :label="$t('description')"
        outlined
        v-model="obCurrencyRate.preview_text"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { CurrencyRate } from "@planetadeleste/vue-mc-gw";

@Component
export default class CurrencyratesSettings extends Vue {
  @VModel({ type: Object, default: () => new CurrencyRate() })
  obCurrencyRate!: CurrencyRate;
}
</script>
